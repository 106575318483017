import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import AppLoading from "./app/components/Loading";
import ResetPasswordForm from "./app/components/LoginForm/ResetPasswordForm";
import RegisterForm from "./app/components/Register/RegisterForm";
import RegisterSuccess from "./app/components/Register/RegisterSuccess";
import RegisterVerify from "./app/components/Register/RegisterVerify";
import { AuthPage } from "./app/pages/Auth";
import { MainPage } from "./app/pages/Main";
import MaterialPage from "./app/pages/Material";
import { ProjectPage } from "./app/pages/Project";
import { ProjectDetailPage } from "./app/pages/Project/ProjectDetail";
import { BuyerSettingPage } from "./app/pages/Settings/BuyerSetting";
import { QuickSettingsPage } from "./app/pages/Settings/QuickSettings";
import { SellerSettingsPage } from "./app/pages/Settings/SellerSettings";
import { authActions, authSelector } from "./app/redux/slides/auth.slide";
import services, { setAuthorization } from "./app/services";
import SurfaceTreatmentPage from "./app/pages/SurfaceTreatment";
import { MachineSettingsPage } from "./app/pages/Machine";
import CustomerPage from "./app/pages/Customer";
import CustomerOverviewPage from "./app/pages/Customer/CustomerOverview";
import { CalculationPartsPage } from "./app/pages/Calculation/CalculationParts";
import "moment/locale/de";
import "bootstrap-icons/font/bootstrap-icons.css";
import "swiper/css";
import "swiper/css/pagination";
import "./index.css";
import "./App.scss";
import moment from "moment";
import { ProjectAdaptPage } from "./app/pages/Project/ProjectAdapt";
import { SelfProjectsPage } from "./app/pages/Project/SelfProjects";
import { Error404Page } from "./app/pages/Error/Error404";
import { SecretPage } from "./app/pages/Secret";
import configServices from "./app/services/configServices";
import { configActions } from "./app/redux/slides/config.slide";
import { ConfigProvider, Modal, notification } from "antd";
import userServices from "./app/services/user.service";
import { userActions } from "./app/redux/slides/user.slide";
import { routers } from "./app/constants/router.constant";
import { langSelector } from "./app/redux/slides/locale.slide";
import i18n from "./app/locales/i18n";
import enUS from "antd/locale/en_US";
import deDe from "antd/locale/de_DE";
import itIT from "antd/locale/it_IT";
import csCZ from "antd/locale/cs_CZ";
import { unreadActions } from "./app/redux/slides/unread.slide";
import LoginRegister from "./app/components/LoginForm/LoginRegister";
import { themes } from "./app/constants/theme.constant";
import { WarningOutlined } from "@ant-design/icons";
// import reCAPTCHA from "./app/utils/reCAPTCHA";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { UserRole } from "app/models/UserRole";

const wMobile = 767;
const wTablet = 1199;

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV === "production"
      ? "https://26bafede506b4e848ec40555affde370@o4505501273292800.ingest.sentry.io/4505555303596032"
      : "",
  integrations:
    process.env.REACT_APP_ENV === "production"
      ? [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Breadcrumbs({
            fetch: true,
          }),
          new Sentry.GlobalHandlers({
            onerror: true,
            onunhandledrejection: true,
          }),
        ]
      : [],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const getTitleSite = (t: any, key: string) => {
  switch (key) {
    case "ensinger":
      return "TECAPARTS | Ensinger";
    case "osl":
      return "OSL quotation";
    default:
      return t("welcome.message") || "goCAD";
  }
};

export default function App() {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  document.title = getTitleSite(t, process.env.REACT_APP_BRAND || "");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector(authSelector);
  const lang = useSelector(langSelector);
  const [lastAccess, setLastAccess] = useState<any>();
  setAuthorization(auth.jwtToken);
  // const [jwtToken, setJwtToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [fontSize, setFontSize] = useState(
    window.screen.width > wMobile
      ? window.screen.width > wTablet
        ? (14 * window.screen.width) / 1920
        : window.matchMedia("(orientation: portrait)").matches
        ? (14 * window.screen.width) / 820
        : (14 * window.screen.width) / 1200
      : (14 * window.screen.width) / 375
  );
  const [isOpenDisconnected, setIsOpenDisconnected] = useState(false);

  const validateMessages = {
    required: t("validate.required"),
  };

  const antLocale = () => {
    switch (lang) {
      case "de":
        return deDe;
      case "it":
        return itIT;
      case "cs":
        return csCZ;
      default:
        return enUS;
    }
  };

  const onLogout = () => {
    setIsOpenDisconnected(false);
    setAuthorization(undefined);
    dispatch(authActions.clear());
    dispatch(unreadActions.clearData());
    navigate(routers.HOME);
  };

  const getMe = async () => {
    try {
      const rs = await userServices.me();
      setIsExpired(false);
      dispatch(userActions.setProfile(rs));
    } catch (error) {}
  };

  const getConfig = async () => {
    try {
      const rs = await configServices.getData();
      dispatch(configActions.setData(rs));
    } catch (error) {}
  };

  const init = async () => {
    setLoading(true);
    await i18n.changeLanguage(lang);
    services.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error) {
          error.message = t("error.default");
          if (
            error?.response?.data?.exception ===
            "io.jsonwebtoken.ExpiredJwtException"
          ) {
            setIsExpired(true);
            onLogout();
          }
          if (error?.code === "ERR_NETWORK") {
            setIsOpenDisconnected(true);
          }
        }

        return Promise.reject(error?.response?.data);
      }
    );
    setLoading(false);
  };
  useEffect(() => {
    moment.locale(lang);
    init();
    getConfig();
  }, []);
  useEffect(() => {
    if (isExpired) {
      dispatch(authActions.setLastAccess(lastAccess));
      api.info({
        message: t("error.user.expired"),
        placement: "topRight",
      });
    }
  }, [isExpired]);
  useEffect(() => {
    if (auth.isLogined && auth.jwtToken) {
      setAuthorization(auth.jwtToken);
      getMe();
      if (auth.lastAccess) {
        setTimeout(() => {
          if(location.pathname.indexOf(`/${auth.user?.role}`) > -1) {
            navigate(`${auth.lastAccess || `/${auth.user?.role || ""}`}`);
          } else {
            navigate(`/${auth.user?.role || ""}`);
          }
          dispatch(authActions.setLastAccess(""));
        }, 200);
      } else {
        if (location.pathname.indexOf(auth.user?.role) > -1) {
          setTimeout(() => {
            navigate(location.pathname);
          }, 200);
        } else {
          setTimeout(() => {
            navigate(`/${auth.user?.role || ""}`);
          }, 200);
        }
      }
    }
  }, [auth.isLogined, auth.jwtToken, auth.user]);
  useEffect(() => {
    if (
      location.pathname.indexOf("/buyer") > -1 ||
      location.pathname.indexOf("/seller") > -1
    ) {
      setLastAccess(location.pathname);
    }
  }, [location]);
  if (loading) return <></>;
  return (
    <ConfigProvider
      locale={antLocale()}
      theme={{
        token: {
          fontSize,
          ...themes[process.env.REACT_APP_BRAND || "dev"],
        },
      }}
      form={{
        validateMessages,
      }}
    >
      {contextHolder}
      <AppLoading />
      <div className={`app-version ${auth.isLogined ? "logined" : ""}`}>
        powered by{" "}
        <a href="https://gocad.de/" target="_blank" rel="noreferrer">
          goCAD.
        </a>
      </div>
      {/* Routes nest inside one another. Nested route paths build upon
              parent route paths, and nested route elements render inside
              parent route elements. See the note about <Outlet> below. */}
      <SentryRoutes>
        {/* <Route path="counter" element={<Counter />} /> */}
        {!auth.isLogined && (
          <>
            <Route path="/" element={<AuthPage />}>
              <Route index element={<LoginRegister />} />
              <Route path="login" element={<LoginRegister />} />
              <Route path="register" element={<RegisterForm />} />
              <Route path="register/success" element={<RegisterSuccess />} />
              <Route path="verify" element={<RegisterVerify />} />
              <Route path="reset-password" element={<ResetPasswordForm />} />
            </Route>
            <Route path="/" element={<SecretPage />}>
              <Route path="projects/:id" element={<ProjectDetailPage />} />
              <Route
                path="projects/viewByCode/:accessCode"
                element={<ProjectDetailPage />}
              />
            </Route>
          </>
        )}

        {auth.isLogined && auth.jwtToken && auth.user && (
          <>
            <Route path="/" element={<MainPage />}>
              <Route index element={<ProjectPage />} />
            </Route>
            {auth.user?.role === UserRole.BUYER && (
              <Route path="/buyer" element={<MainPage />}>
                <Route index element={<ProjectPage />} />
                <Route path="" element={<ProjectPage />} />
                <Route path="received-offers" element={<ProjectPage />} />
                <Route path="requested-offers" element={<ProjectPage />} />
                <Route path="completed" element={<ProjectPage />} />
                <Route path="cancelled" element={<ProjectPage />} />
                <Route path="archive" element={<ProjectPage />} />
                <Route path="projects/:id" element={<ProjectDetailPage />} />
                <Route path="account-settings" element={<BuyerSettingPage />} />
                <Route
                  path="projects/:projectId/calculations"
                  element={<CalculationPartsPage />}
                />
                <Route path="*" element={<Error404Page />} />
              </Route>
            )}
            {auth.user?.role === UserRole.SELLER && (
              <Route path="/seller" element={<MainPage />}>
                <Route index element={<ProjectPage />} />
                <Route path="sent-offers" element={<ProjectPage />} />
                <Route path="completed" element={<ProjectPage />} />
                <Route path="cancelled" element={<ProjectPage />} />
                <Route path="projects/:id" element={<ProjectDetailPage />} />
                <Route
                  path="projects/:id/adapt"
                  element={<ProjectAdaptPage />}
                />
                <Route path="customers" element={<CustomerPage />} />
                <Route
                  path="customers/:id"
                  element={<CustomerOverviewPage />}
                />
                <Route path="my-projects" element={<SelfProjectsPage />} />
                <Route path="self-projects/archive" element={<ProjectPage />} />
                <Route
                  path="self-projects/drafts"
                  element={<SelfProjectsPage />}
                />
                <Route
                  path="self-projects/dispatched-offers"
                  element={<SelfProjectsPage />}
                />
                <Route
                  path="projects/:projectId/calculations"
                  element={<CalculationPartsPage />}
                />
                <Route
                  path="account-settings"
                  element={<SellerSettingsPage />}
                />
                <Route path="quick-settings" element={<QuickSettingsPage />} />
                <Route path="material-settings" element={<MaterialPage />} />
                <Route
                  path="machine-settings"
                  element={<MachineSettingsPage />}
                />
                <Route
                  path="surface-treatment-settings"
                  element={<SurfaceTreatmentPage />}
                />
                <Route path="*" element={<Error404Page />} />
              </Route>
            )}
          </>
        )}
        <Route path="*" element={<Error404Page />} />
      </SentryRoutes>
      <Modal
        open={isOpenDisconnected}
        cancelButtonProps={{
          hidden: true,
        }}
        onCancel={setIsOpenDisconnected.bind(null, false)}
        onOk={onLogout}
        okText={auth.isLogined ? t("logout") : t("ok")}
        centered
      >
        <div className="d-flex flex-column align-items-center">
          <WarningOutlined
            style={{ fontSize: "5rem" }}
            className="mb-3 text-danger"
          />
          <h5>Opps!</h5>
          <div className="sub-l">
            <i>Something went wrong.</i>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
