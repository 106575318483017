/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import {
  MessageOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Table,
  Button,
  Alert,
  Popover,
  Modal,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  Tooltip,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calculation, Part } from "../../models/Part";
import { PartViewMode } from "../../models/PartViewMode";
import { ListViewMode, Project, ProjectStatus } from "../../models/Project";
import { authSelector } from "../../redux/slides/auth.slide";
import partServices from "../../services/part.service";
import projectServices from "../../services/project.service";
import AddressForm from "../Billing/AddressForm";
import CurrencyFormat from "../Format/CurrencyFormat";
import CadFileItem from "./CadFileItem";
import PriceReference from "./PriceReference";
import "./calculation.module.scss";
import { projectActions } from "../../redux/slides/project.slide";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import CadFileIcon from "../SVGs/CadFileIcon";
import NoCommentIcon from "../SVGs/NoCommentIcon";
import { profileSelector } from "../../redux/slides/user.slide";
import { User } from "../../models/User";
import { useNavigate, useParams } from "react-router-dom";
import { UserRole } from "../../models/UserRole";
import PartImage from "./PartImage";
import DropdownActions from "./DropdownActions";
import "./listPart.module.scss";
import { ActionMode } from "../../models/PartActionMode";
import { useTranslation } from "react-i18next";
import PriceAdaptForm from "./PriceAdaptForm";
import { Price, PriceSurfaceTreatments } from "../../models/Price";
import { ShippingOption } from "../../models/ShippingOption";
import InputInlineApi from "../Form/InputInlineApi";
import LocalizationName from "../Locale/LocalizationName";
import NumberFormat from "../Format/NumberFormat";

interface DefaultProps {
  project: Project;
  mode: ListViewMode;
  onCheckOut?: any;
  onRequestOffer?: any;
}

const PartQuantityForm = function (props: any) {
  const dispatch = useDispatch();
  const { part, onPartChange } = props;
  const [isQuantityEdit, setIsQuantityEdit] = useState(false);
  const [loadingQuantity, setLoadingQuantity] = useState(false);
  const [referenceCosts, setReferenceCosts] = useState<any>();

  const getReferenceCosts = async () => {
    try {
      const rs = await partServices.getReferenceCosts(part?.id);
      setReferenceCosts(rs);
    } catch (error) {}
  };

  useEffect(() => {
    if (part.auto && part.totalPrice > 0) {
      getReferenceCosts();
    }
  }, []);

  const onFormQuantityChange = (values: any) => {
    setIsQuantityEdit(values.quantity !== part?.quantity);
  };

  const onFormQuantitySubmit = async (values: any) => {
    setLoadingQuantity(true);
    try {
      const rs = await partServices.updateQuantity({
        id: values.id,
        quantity: values.quantity,
      });
      setIsQuantityEdit(false);
      dispatch(projectActions.setPart(rs));
      if (onPartChange) onPartChange(rs);
    } catch (error) {}
    setLoadingQuantity(false);
  };
  return (
    <Form
      id="formQuantity"
      disabled={loadingQuantity}
      initialValues={part}
      className="ms-auto app-form form-quantity"
      onValuesChange={onFormQuantityChange}
      onFinish={onFormQuantitySubmit}
    >
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Space.Compact>
        <Form.Item name="quantity" className="mb-0" required>
          <InputNumber
            style={{ width: "120px" }}
            min={0}
            addonBefore={
              referenceCosts && (
                <Popover
                  placement="left"
                  content={<PriceReference data={referenceCosts} />}
                  trigger="hover"
                >
                  <Button type="text" size="small" className="h-100">
                    <InfoCircleOutlined />
                  </Button>
                </Popover>
              )
            }
          />
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            type="primary"
            htmlType="submit"
            icon={<CheckOutlined />}
            disabled={!isQuantityEdit}
            loading={loadingQuantity}
          />
        </Form.Item>
      </Space.Compact>
    </Form>
  );
};

function ListParts(props: DefaultProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { project, mode, onCheckOut } = props;
  const auth = useSelector(authSelector);
  const profile: User = useSelector(profileSelector);
  const { accessCode } = useParams();
  const [formRequestOffer] = Form.useForm();
  const [dataAuto, setDataAuto] = useState<Part[]>(
    project?.parts?.filter((p) => p.auto) || []
  );
  const [dataManual, setDataManual] = useState<Part[]>(
    project?.parts?.filter((p) => !p.auto) || []
  );
  const [part, setPart] = useState<Part>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRequestOfferOpen, setIsRequestOfferOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isOpenMoveCloneParts, setIsOpenMoveCloneParts] = useState(false);
  const [requestingOffer, setRequestingOffer] = useState(false);
  const [errorRequestOffer, setErrorRequestOffer] = useState<any>(false);
  const [priceSurfaceTreatments, setPriceSurfaceTreatments] =
    useState<PriceSurfaceTreatments[]>();
  const [totalPriceSurfacetreatment, setTotalPriceSurfacetreatment] =
    useState(0);

  const getPriceSurfaceTreatments = async () => {
    try {
      const rs: Price = await projectServices.getPrice({
        id: project.id,
        shippingOption: ShippingOption.STANDARD,
      });
      setPriceSurfaceTreatments(rs.priceSurfaceTreatments);
      const sum =
        rs.priceSurfaceTreatments?.reduce((a, p) => {
          return a + p.differenceCost;
        }, 0) || 0;
      setTotalPriceSurfacetreatment(sum);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataAuto && dataAuto.length > 0 && mode === ListViewMode.EDIT)
      getPriceSurfaceTreatments();
  }, [dataAuto]);

  useEffect(() => {
    if (
      !!formRequestOffer &&
      !project.order?.billingAddress &&
      profile?.billingAddress
    ) {
      const billingAddress: any = { ...profile.billingAddress };
      billingAddress.id = undefined;
      formRequestOffer.setFieldValue("billingAddress", billingAddress);
    }
  }, [formRequestOffer]);

  useEffect(() => {
    if (project) {
      setDataAuto(project.parts?.filter((p) => p.auto) || []);
      setDataManual(project?.parts?.filter((p) => !p.auto) || []);
    }
  }, [project]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < moment().add(14, "days").endOf("day");
  };

  const showPartModal = (data: Part) => {
    setPart(data);
    setIsModalOpen(true);
  };

  const showRequestOffer = () => {
    setIsRequestOfferOpen(true);
  };
  const onRequestOffer = () => {
    showRequestOffer();
  };

  const handleCancel = () => {
    setPart(undefined);
    setIsModalOpen(false);
  };
  const handleRequestOfferCancel = () => {
    setIsRequestOfferOpen(false);
  };

  const onRequestOfferSubmit = async (values: any) => {
    setRequestingOffer(true);
    setErrorRequestOffer(false);
    const data = {
      ...values,
      id: project.id,
      deliveryDate: values.deliveryDate.format("YYYY-MM-DD"),
      billingAddress: {
        ...values.billingAddress,
        id: null,
      },
      shippingAddress: {
        ...values.billingAddress,
        id: null, //project.order?.shippingAddress?.id,
      },
    };
    try {
      const rs = await projectServices.requestOffer(data);
      const p = {
        ...project,
        parts: [...dataAuto],
        linkedProject: {
          id: rs.id,
          name: rs.name,
          createdBy: rs.createdBy,
          status: rs.status,
        },
      };
      dispatch(projectActions.setProject(p));
      setDataManual([]);
      if (dataAuto.length === 0) {
        navigate(`/${auth.user?.role}/projects/${project.id}`);
      }
      handleRequestOfferCancel();
    } catch (error) {
      setErrorRequestOffer(error);
    }
    setRequestingOffer(false);
  };

  const onPartChange = (part: Part) => {
    const listParts = part.auto ? dataAuto : dataManual;
    const index = listParts.findIndex((p) => p.id === part.id);
    if (index > -1 && listParts) {
      listParts[index] = part;
      part.auto ? setDataAuto([...listParts]) : setDataManual([...listParts]);
    }
  };

  const onActionSubmitted = (mode: any, part: Part) => {
    switch (mode) {
      case ActionMode.VIEW:
        showPartModal(part);
        break;
      default:
        break;
    }
  };

  const downloadCadFile = async (part: any) => {
    if (part) {
      try {
        await partServices.downloadCadFile({
          id: part.id,
          name: part.partFileName,
        });
      } catch (error) {}
    }
  };

  const handleChangePartName = async (name: string, part: Part) => {
    try {
      const rs: Part = await partServices.updateName(part?.id, name);

      return rs.name;
    } catch (error) {}
  };

  const columns: ColumnsType<Part> = [
    // {
    //   title: "Id",
    //   dataIndex: "id",
    //   width: "50px",
    // },
    {
      title: "",
      dataIndex: "preview2dUrl",
      render: (data, record) => (
        <PartImage
          width={"5rem"}
          src={data || record?.dxfFileUrl || record?.previewPngUrl}
          part={record}
        />
      ),
    },
    {
      title: t("part.partName"),
      dataIndex: "name",
      width: "12vw",
      render: (data, record: Part) => (
        <div className="part-cell-name">{data || record.partFileName}</div>
      ),
    },
    {
      title: t("files"),
      dataIndex: "id",
      width: "100px",
      render: (_, record) => (
        <>
          <Space direction="horizontal" size={12}>
            <Tooltip placement="top" title={record.partFileName}>
              <a onClick={downloadCadFile.bind(null, record)} target="_blank">
                <CadFileIcon />
              </a>
            </Tooltip>
            {record.pdfFileName && (
              <Tooltip placement="top" title={record.pdfFileName}>
                <a href={record.technicalDrawingUrl} target="_blank">
                  <PdfFileIcon />
                </a>
              </Tooltip>
            )}
          </Space>
        </>
      ),
    },
    {
      title: "Material",
      dataIndex: "material",
      width: "10rem",
      render: (data) => (
        <>
          {data ? (
            <>
              {data?.name}/{data?.number || "--"}
            </>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      width: "8rem",
      render: (data, record) => {
        return mode === ListViewMode.EDIT ? (
          <PartQuantityForm part={record} onPartChange={onPartChange} />
        ) : (
          <>{data}</>
        );
      },
    },
    {
      title: t("price.unitPrice"),
      dataIndex: "unitPrice",
      align: "right",
      width:
        mode === ListViewMode.EDIT || mode === ListViewMode.PRICE_ADAPT
          ? "14rem"
          : "8rem",
      render: (data, record: Part) => {
        return (mode === ListViewMode.EDIT ||
          mode === ListViewMode.PRICE_ADAPT) &&
          auth.user?.role === UserRole.SELLER ? (
          <PriceAdaptForm part={record} />
        ) : (
          <div
            className={`text-right ${
              record.approved ? "" : "text-decoration-line-through"
            }`}
          >
            <CurrencyFormat
              value={
                record.auto
                  ? data
                  : auth.user?.role === UserRole.SELLER
                  ? data
                  : 0
              }
              adaptPrice={record?.adaptedUnitPrice}
              isHideAdapt={
                (project?.selfCalculation &&
                  (!auth.isLogined || auth.user?.role === UserRole.BUYER)) ||
                (!project?.selfCalculation && project?.manual)
              }
              hiddenValue={
                auth.user?.role === UserRole.BUYER &&
                !record?.auto &&
                (project?.status === ProjectStatus.IN_PROGRESS ||
                  project?.status === ProjectStatus.REQUESTED_OFFER)
              }
            />
          </div>
        );
      },
    },
    {
      title: t("price.partPriceTotal"),
      dataIndex: "unitPrice",
      align: "right",
      width: "140px",
      render: (data, record: Part) => (
        <div
          className={`text-right fw-semibold ${
            record.approved ? "" : "text-decoration-line-through"
          }`}
        >
          <CurrencyFormat
            value={data * record.quantity}
            adaptPrice={
              record?.adaptedUnitPrice != null ? record.totalPrice : undefined
            }
            isHideAdapt={
              data == null ||
              (project.selfCalculation &&
                (!auth.isLogined || auth.user?.role === UserRole.BUYER)) ||
              (!project.selfCalculation && project?.manual)
            }
            hiddenValue={
              auth.user?.role === UserRole.BUYER &&
              !record?.auto &&
              (project?.status === ProjectStatus.IN_PROGRESS ||
                project?.status === ProjectStatus.REQUESTED_OFFER)
            }
          />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "additionalComments",
      width: "3em",
      render: (data) => (
        <>
          <Popover
            content={
              data ? (
                <div style={{ maxWidth: "40vw", lineBreak: "anywhere" }}>
                  {data}
                </div>
              ) : (
                <>
                  <i>{t("empty")}</i>
                </>
              )
            }
            title={t("part.label.additionalComments")}
            trigger="hover"
            placement="left"
          >
            {data ? (
              <MessageOutlined />
            ) : (
              <NoCommentIcon style={{ width: 16, height: 16, opacity: 0.5 }} />
            )}
          </Popover>
        </>
      ),
    },
    {
      title: t("part.emission"),
      dataIndex: "calculation",
      render: (data: Calculation) => (
        <>
          <NumberFormat value={data?.emission} /> kg
        </>
      ),
    },
    {
      title: t("action"),
      key: "operation",
      // fixed: "right",
      width: 150,
      render: (_: any, record: Part) => (
        <Space direction="horizontal">
          {
            <DropdownActions
              project={project}
              part={record}
              onSubmitted={onActionSubmitted}
              hiddenMoveBtn={mode === ListViewMode.CHECKOUT}
            />
          }
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (dataAuto) {
      const total = dataAuto.reduce((accumulator, object) => {
        return accumulator + object.totalPrice;
      }, 0);
      setTotalPrice(total);
    }
  }, [dataAuto]);

  const submitCheckOut = () => {
    // setIsClickCheckOut(true);
    // if (dataManual.length > 0) {
    //   showRequestOffer();
    //   return;
    // }
    if (onCheckOut) onCheckOut();
  };
  return (
    <>
      {!project.selfCalculation && (
        <>
          {dataAuto.length > 0 && (
            <div className="mt-3">
              <h6 className="mb-3">{t("project.autoPartsTitle")}</h6>
              {!project.selfCalculation && mode === ListViewMode.EDIT && (
                <Alert
                  type="info"
                  message={t("project.autoNote")}
                  icon={<InfoCircleOutlined />}
                  showIcon
                />
              )}
              <Table
                className="project-table mt-3"
                columns={columns}
                showSorterTooltip={false}
                rowKey={(record) => `project-${record.id}`}
                dataSource={dataAuto}
                pagination={false}
                rowClassName={(record) => {
                  return record.approved ? "" : "part-row-decline";
                }}
                scroll={{ x: 1000 }}
              />
              {project.status !== ProjectStatus.CHECKOUTED &&
                mode === ListViewMode.EDIT && (
                  <div className="d-flex flex-column align-items-end justify-content-end mt-3">
                    <div className="d-flex flex-column gap-2 b-price-info">
                      {!!totalPriceSurfacetreatment && (
                        <>
                          <div className="d-flex">
                            <Popover
                              overlayClassName="surface-prices"
                              className="col-9 text-end"
                              placement="top"
                              content={
                                <div>
                                  {t("surfaceTreatmentSurchargeDesc")}
                                  <table className="table mb-0 text-white">
                                    <thead>
                                      <tr>
                                        <th scope="col">{t("method")}</th>
                                        <th scope="col" className="text-end">
                                          {t("price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {!!priceSurfaceTreatments &&
                                        priceSurfaceTreatments.map(
                                          (item, index) => (
                                            <tr
                                              key={`surfacetreatment-${index}`}
                                            >
                                              <td scope="col">
                                                <LocalizationName
                                                  localizations={
                                                    item.localizations
                                                  }
                                                  valueDefault={item.method}
                                                />
                                              </td>
                                              <td
                                                scope="col"
                                                className="text-end"
                                              >
                                                <CurrencyFormat
                                                  value={item.differenceCost}
                                                />
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              }
                              trigger="hover"
                            >
                              <label>
                                {t("price.surfacetreatments")}:{" "}
                                <InfoCircleOutlined />
                              </label>
                            </Popover>
                            <label className="ms-auto">
                              <CurrencyFormat
                                value={totalPriceSurfacetreatment}
                              />
                            </label>
                          </div>
                          <div className="d-flex">
                            <label className="col-9 text-end">
                              {t("price.allPartsTotal")}:
                            </label>
                            <label className="ms-auto">
                              <CurrencyFormat value={totalPrice} />
                            </label>
                          </div>
                          <div className="d-flex">
                            <label className="col-9 text-end">
                              {t("price.netTotal")}:
                            </label>
                            <label className="fs-5 ms-auto">
                              <CurrencyFormat
                                value={totalPrice + totalPriceSurfacetreatment}
                              />
                            </label>
                          </div>
                        </>
                      )}
                      {!totalPriceSurfacetreatment && (
                        <div className="text-end">
                          <label>Total:</label>
                          <label className="fs-5 ms-2">
                            <CurrencyFormat value={totalPrice} />
                          </label>
                        </div>
                      )}
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="mt-3"
                      onClick={submitCheckOut}
                    >
                      {t("project.checkOut")}
                    </Button>
                  </div>
                )}
            </div>
          )}
          {project.linkedProject &&
            !project.manual &&
            project.linkedProject.status !== ProjectStatus.IN_PROGRESS &&
            mode !== ListViewMode.CHECKOUT && (
              <div className="mt-5">
                <Alert
                  type="info"
                  message={
                    <label className="sub-l mb-0">
                      {t("project.manualPartsTitle")}
                    </label>
                  }
                  description={
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("project.manual.note", {
                            url: `/${auth.user?.role}/projects/${project.linkedProject?.id}`,
                          }),
                        }}
                      ></div>
                      {/* <a
                        className="fw-bold"
                        href={`/${auth.user?.role}/projects/${project.linkedProject?.id}`}
                        target="_blank"
                      >
                        <Button type="default" className="mt-3">
                          {t("project.btn.goToManual")} <ArrowRightOutlined />
                        </Button>
                      </a> */}
                    </>
                  }
                  icon={<InfoCircleOutlined />}
                  showIcon
                />
              </div>
            )}
          {dataManual.length > 0 && mode !== ListViewMode.CHECKOUT && (
            <div className="mt-3">
              <h6 className="mb-3">{t("project.manualPartsTitle")}</h6>
              {(project.status === ProjectStatus.IN_PROGRESS ||
                project.status === ProjectStatus.REQUESTED_OFFER ||
                (auth.user?.role === UserRole.BUYER &&
                  project.status === ProjectStatus.PRICE_ADAPTED)) && (
                <Alert
                  message={
                    <>
                      {auth.user?.role === UserRole.BUYER
                        ? project.status !== ProjectStatus.PRICE_ADAPTED
                          ? t("project.manualNote")
                          : t("project.manual.buyer.note")
                        : t("project.manual.seller.note")}
                    </>
                  }
                  type="info"
                  icon={<InfoCircleOutlined />}
                  showIcon
                />
              )}
              <Table
                className="project-table mt-3"
                columns={columns}
                showSorterTooltip={false}
                rowKey={(record) => `project-${record.id}`}
                dataSource={dataManual}
                pagination={false}
                rowClassName={(record) => {
                  return record.approved ? "" : "decline";
                }}
              />
              {project.status !== ProjectStatus.REQUESTED_OFFER &&
                mode === ListViewMode.EDIT && (
                  <div className="d-flex flex-row align-items-center justify-content-end mt-3">
                    <Button
                      type="primary"
                      className="ms-4"
                      onClick={onRequestOffer}
                    >
                      {t("project.requestOffer")}
                    </Button>
                  </div>
                )}
            </div>
          )}
          {project.linkedProject &&
            project.manual &&
            project.linkedProject.status !== ProjectStatus.IN_PROGRESS && (
              <div className="mt-4">
                <Alert
                  type="info"
                  message={
                    <label className="sub-l mb-0">
                      {t("project.autoPartsTitle")}
                    </label>
                  }
                  description={
                    <a
                      href={`/${auth.user?.role}/projects/${project.linkedProject?.id}`}
                      target="_blank"
                    >
                      <Button>
                        {t("project.btn.goToAuto")} <ArrowRightOutlined />
                      </Button>
                    </a>
                  }
                  icon={<InfoCircleOutlined />}
                  showIcon
                />
              </div>
            )}
          {
            <Modal
              title={t("project.requestOffer")}
              open={isRequestOfferOpen}
              onCancel={handleRequestOfferCancel}
              onOk={formRequestOffer.submit}
              okButtonProps={{
                loading: requestingOffer,
              }}
              cancelButtonProps={{
                disabled: requestingOffer,
              }}
              centered
              width={"40%"}
            >
              <Alert
                description={t("project.requestOfferNote")}
                type="warning"
                icon={<WarningOutlined />}
                showIcon
              />
              {errorRequestOffer && (
                <Alert
                  type="error"
                  className="mt-3"
                  message={errorRequestOffer.message}
                />
              )}
              <Form
                form={formRequestOffer}
                layout="vertical"
                className="app-form mt-3"
                onFinish={onRequestOfferSubmit}
                initialValues={{
                  deliveryDate: dayjs().add(15, "day"),
                  billingAddress: project.order.billingAddress,
                }}
              >
                <Form.Item
                  name="deliveryDate"
                  label={t("project.requestOffer.deliveryDate")}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: "200px" }}
                    format="MM/DD/YYYY"
                    disabledDate={disabledDate}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item>
                  <AddressForm
                    form={formRequestOffer}
                    data={formRequestOffer.getFieldValue("billingAddress")}
                    title={t("billingAddress")}
                    name="billingAddress"
                  />
                </Form.Item>
              </Form>
            </Modal>
          }
        </>
      )}
      {project.selfCalculation && (
        <>
          {project.parts && project.parts?.length > 0 && (
            <div className="mt-3">
              <Table
                className="project-table mt-3"
                columns={columns}
                showSorterTooltip={false}
                rowKey={(record) => `project-${record.id}`}
                dataSource={project.parts}
                pagination={false}
                rowClassName={(record) => {
                  return record.approved ? "" : "part-row-decline";
                }}
              />
              {project.status !== ProjectStatus.CHECKOUTED &&
                mode === ListViewMode.EDIT && (
                  <div className="d-flex flex-row align-items-center justify-content-end mt-3">
                    <label>Total:</label>
                    <label className="fs-4 ms-2">
                      <CurrencyFormat value={totalPrice} />
                    </label>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ms-4"
                      onClick={submitCheckOut}
                    >
                      {t("project.checkOut")}
                    </Button>
                  </div>
                )}
            </div>
          )}
        </>
      )}
      {part && (
        <Modal
          // title={t("part")}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          centered
          width="80vw"
        >
          <CadFileItem
            className="no-border"
            part={part}
            mode={
              !project.selfCalculation &&
              (project.status === ProjectStatus.CHECKOUTED ||
                project.status === ProjectStatus.REQUESTED_OFFER) &&
              auth.user.role === UserRole.SELLER
                ? PartViewMode.ADAPT
                : PartViewMode.READ_ONLY
            }
            onChange={onPartChange}
          />
        </Modal>
      )}
      <Modal
        open={isOpenMoveCloneParts}
        onCancel={setIsOpenMoveCloneParts.bind(null, false)}
        footer={null}
      >
        {/* <MoveAndCloneParts mode="MOVE" /> */}
      </Modal>
    </>
  );
}

export default ListParts;
