export const themes: any = {
  dev: {
    colorPrimary: "#ffcb3d",
    fontFamily: "unset",
    colorPrimaryText: "#222222",
  },
  brecht: {
    colorPrimary: "#007cbc",
    fontFamily: "unset",
  },
  hertenberger: {
    colorPrimary: "#7ab41d",
    fontFamily: "unset",
  },
  lst247 : {
    colorPrimary: "#FF5057",
    fontFamily: "unset",
  },
  osl: {
    colorPrimary: "#6599cc",
    fontFamily: "unset",
  },
  rich: {
    colorPrimary: "#4C4C4A",
    fontFamily: "unset",
  },
  ensinger: {
    colorPrimary: "#125687",
    fontFamily: "unset",
  },
  burg: {
    colorPrimary: "#185EAA",
    fontFamily: "unset",
  },
  jakobdreherei: {
    colorPrimary: "#D98200",
    fontFamily: "unset",
  },
  kurios: {
    colorPrimary: "#DC0022",
    fontFamily: "unset",
  }
};
